import { DelimiterNames, wrapSmartrrSnippet } from "../utils";

export const SMARTRR_MAIN_PRODUCT_FORM_ID = wrapSmartrrSnippet(
  `
  <product-info id="ProductInfo-{{ section.id }}" class="product__info-container{% if section.settings.enable_sticky_info %}product__info-container--sticky{% endif %}"data-smartrr-form-id="{{ product.id }}">
  `,
  DelimiterNames.PRODUCT_ID
);
export const SMARTRR_MAIN_PRODUCT_FORM_ID_REMOVE_BEFORE_VALUE = `{%- assign product_form_id = 'product-form-' | append: section.id -%}`;
export const TO_REPLACE_MAIN_PRODUCT_FORM_ID = `<div class="product__info-wrapper grid__item{% if settings.page_width > 1400 and section.settings.media_size == "small" %} product__info-wrapper--extra-padding{% endif %}`;

export const SMARTRR_MAIN_PRODUCT_RENDER = wrapSmartrrSnippet(
  `
  {% render 'smartrr-product' %}
  `,
  DelimiterNames.PRODUCT_RENDER
);
export const TO_REPLACE_MAIN_PRODUCT_RENDER = `<div class="product-form__buttons">`;

export const SMARTRR_MAIN_PRODUCT_DIV = wrapSmartrrSnippet(
  `
  <div data-smartrr-product-id="{{ product.id }}" data-smartrr-price-style="overwrite-compare">
  <span data-smartrr-compare-price></span>
  <span data-smartrr-regular-price></span>
  <span data-smartrr-subscribe-price></span>
</div>
`,
  DelimiterNames.PRODUCT_DIV
);
export const TO_REPLACE_MAIN_PRODUCT_DIV = `{%- when 'price' -%}`;
export const SMARTRR_MAIN_PRODUCT_REMOVE_BEFORE_VALUE = `{%- when 'description' -%}`;
