import { wrapSmartrrSnippet, DelimiterNames } from "./utils";

export const SMARTRR_INCLUDE_PASSWORDLESS_SNIPPET = wrapSmartrrSnippet(
  `<div id="smartrr_passwordless_form" class="customer login section-{{ section.id }}-padding smartrr-hide-siblings">
    {%- form 'customer_login', novalidate: 'novalidate' -%}
      {%- if form.errors -%}
        <div class="form__errors">
          <h2 class="form__message" tabindex="-1" autofocus>
            <span class="visually-hidden">{{ 'accessibility.error' | t }} </span>
            <svg aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 13 13">
              <circle cx="6.5" cy="6.50049" r="5.5" stroke="white" stroke-width="2"/>
              <circle cx="6.5" cy="6.5" r="5.5" fill="#EB001B" stroke="#EB001B" stroke-width="0.7"/>
              <path d="M5.87413 3.52832L5.97439 7.57216H7.02713L7.12739 3.52832H5.87413ZM6.50076 9.66091C6.88091 9.66091 7.18169 9.37267 7.18169 9.00504C7.18169 8.63742 6.88091 8.34917 6.50076 8.34917C6.12061 8.34917 5.81982 8.63742 5.81982 9.00504C5.81982 9.37267 6.12061 9.66091 6.50076 9.66091Z" fill="white"/>
              <path d="M5.87413 3.17832H5.51535L5.52424 3.537L5.6245 7.58083L5.63296 7.92216H5.97439H7.02713H7.36856L7.37702 7.58083L7.47728 3.537L7.48617 3.17832H7.12739H5.87413ZM6.50076 10.0109C7.06121 10.0109 7.5317 9.57872 7.5317 9.00504C7.5317 8.43137 7.06121 7.99918 6.50076 7.99918C5.94031 7.99918 5.46982 8.43137 5.46982 9.00504C5.46982 9.57872 5.94031 10.0109 6.50076 10.0109Z" fill="white" stroke="#EB001B" stroke-width="0.7">
            </svg>
          </h2>
          <div>{{ form.errors | default_errors }}</div>
        </div>
      {%- endif -%}
      {% render 'smartrr-passwordless-login' %}
    {%- endform -%}
  </div>`,
  DelimiterNames.DEFAULT
);
