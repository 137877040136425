import { FailureRes, Req, SuccessRes } from "../req";
export interface IAssetResponse {
  asset: IAsset;
}
export interface IAsset {
  attachment?: string;
  content_type: string;
  created_at: string;
  key: string;
  public_url: string;
  size: number;
  source_key: string;
  src: string;
  theme_id: number;
  updated_at: string;
  value?: string;
}

export function getShopifyAsset(
  themeId: number,
  assetId: string,
  client: Req
): Promise<FailureRes | SuccessRes<IAssetResponse>> {
  return client.getReq<IAssetResponse>(`/themes/${themeId}/assets`, {
    query: { "asset[key]": assetId },
  });
}

export function updateShopifyAsset(
  themeId: number,
  assetId: string,
  value: string,
  client: Req
): Promise<FailureRes | SuccessRes<IAssetResponse>> {
  return client.putReq<
    {
      asset: {
        key: string;
        value: string;
      };
    },
    IAssetResponse
  >(`/themes/${themeId}/assets`, {
    reqBody: {
      asset: {
        key: `${assetId}`,
        value,
      },
    },
  });
}
