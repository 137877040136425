import { AssetName } from "./templates";
import { BEGIN_DELIMITER, DISABLE_AUTO_LIQUID_EDIT_TEXT, END_DELIMITER } from "./templates/utils";

function getDelimiters(value: string) {
  const beginString = "<!-- BEGIN";
  const endString = "<!-- END";
  const finalString = "-->";
  const delimiterBegin = value.substring(
    value.indexOf(beginString),
    value.indexOf(finalString) + finalString.length
  );
  const delimiterEnd = value.substring(
    value.indexOf(endString),
    value.lastIndexOf(finalString) + finalString.length
  );

  return [delimiterBegin, delimiterEnd];
}

export function injectLiquid(
  assetId: string,
  assetValue: string,
  snippet: string,
  toReplace?: string | RegExp,
  replacePosition?: "before" | "after",
  toRemoveBefore?: string
) {
  // allow shops to disable our auto-editing with a comment
  if (assetValue.includes(DISABLE_AUTO_LIQUID_EDIT_TEXT)) {
    return assetValue;
  }

  // don't want to overwrite theme but do want to create if it doesn't exist
  if (assetId === AssetName.SNIPPETS_SMARTRR_ACCOUNT_THEME) {
    return assetValue || snippet;
  }

  if (assetId.includes("smartrr")) {
    return snippet;
  }

  //Getting the unique delimiter from snippet
  const [delimiterBegin, delimiterEnd] = getDelimiters(snippet);

  return replaceSnippetInTemplate(
    assetId,
    assetValue,
    snippet,
    toReplace,
    replacePosition,
    delimiterBegin,
    delimiterEnd,
    toRemoveBefore
  );
}

function replaceSnippetInTemplate(
  assetId: string,
  existingValue: string,
  snippet: string,
  toReplace: string | RegExp = "",
  replacePosition: "before" | "after" = "before",
  delimiterBegin: string,
  delimiterEnd: string,
  toRemoveBefore?: string
) {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const DELIMITER_SEARCH = new RegExp(`${delimiterBegin}.*?${delimiterEnd}`);

  if (existingValue.includes(delimiterBegin)) {
    return existingValue.replace(DELIMITER_SEARCH, snippet);
  }

  // To remove multiple lines from theme because we can only match one line at a time
  if (toRemoveBefore && typeof toReplace === "string") {
    const indexAfter = existingValue.indexOf(toReplace);
    const indexBefore = existingValue.indexOf(toRemoveBefore);
    const endOfLineIndex = existingValue.indexOf("\n", indexAfter);
    toReplace = existingValue.slice(indexAfter, endOfLineIndex === -1 ? undefined : endOfLineIndex);
    const firstHalfValue = existingValue.slice(0, endOfLineIndex === -1 ? undefined : endOfLineIndex);
    const secondHalfValue = existingValue.slice(indexBefore);
    existingValue = `${firstHalfValue}\n${secondHalfValue}`;
  }

  // Single click install on debut theme
  if (assetId === AssetName.SECTIONS_PRODUCT_TEMPLATE) {
    return existingValue.replace(toReplace, snippet);
  }

  // if blank string, interpret as intending to inject at beginning or end
  // (depending on position)
  if (toReplace === "") {
    return replacePosition === "before" ? `${snippet}\n${existingValue}` : `${existingValue}\n${snippet}`;
  }

  const replaceRe = existingValue.match(toReplace);
  if (!replaceRe) {
    console.error(`couldnt find pattern ${toReplace.toString()} in string ${existingValue}`);
    return;
  }

  return existingValue.replace(
    replaceRe[0],
    replacePosition === "before" ? `${snippet}\n${replaceRe}` : `${replaceRe}\n${snippet}`
  );
}

// Current functionality for removing snippets is inactive
//UPD: its used to change between passwordless login and traditional
export function clearFromSnippet(existingValue: string) {
  const beginDelimiterIndex = existingValue.indexOf(BEGIN_DELIMITER);
  const endDelimiterIndex = existingValue.indexOf(END_DELIMITER);

  if (beginDelimiterIndex !== -1 && endDelimiterIndex !== -1) {
    return `${existingValue.slice(0, beginDelimiterIndex).trim()}\n${existingValue
      .slice(endDelimiterIndex + END_DELIMITER.length)
      .trim()}`.trim();
  }

  return existingValue;
}
