export const BEGIN_DELIMITER = "<!-- BEGIN SMARTRR SNIPPET -->";
export const END_DELIMITER = "<!-- END SMARTRR SNIPPET -->";
export const BEGIN_PRODUCT_DIV_DELIMITER = "<!-- BEGIN SMARTRR DIV SNIPPET -->";
export const END_PRODUCT_DIV_DELIMITER = "<!-- END SMARTRR DIV SNIPPET -->";
export const BEGIN_PRODUCT_ID_DELIMITER = "<!-- BEGIN SMARTRR ID SNIPPET -->";
export const END_PRODUCT_ID_DELIMITER = "<!-- END SMARTRR ID SNIPPET -->";
export const BEGIN_PRODUCT_RENDER_DELIMITER = "<!-- BEGIN SMARTRR RENDER SNIPPET -->";
export const END_PRODUCT_RENDER_DELIMITER = "<!-- END SMARTRR RENDER SNIPPET -->";
export const DISABLE_AUTO_LIQUID_EDIT_TEXT = "SMARTRR_CUSTOMIZED_NO_AUTO_EDIT";
export const DISABLE_AUTO_LIQUID_EDIT_COMMENT = `<!-- ${DISABLE_AUTO_LIQUID_EDIT_TEXT} -->`;

export enum DelimiterNames {
  "DEFAULT",
  "PRODUCT_DIV",
  "PRODUCT_ID",
  "PRODUCT_RENDER",
}

export function wrapSmartrrSnippet(snippet: string, delimiter: DelimiterNames) {
  switch (delimiter) {
    case DelimiterNames.DEFAULT:
      return [BEGIN_DELIMITER, snippet, END_DELIMITER].join("");
    case DelimiterNames.PRODUCT_DIV:
      return [BEGIN_PRODUCT_DIV_DELIMITER, snippet, END_PRODUCT_DIV_DELIMITER].join("");
    case DelimiterNames.PRODUCT_ID:
      return [BEGIN_PRODUCT_ID_DELIMITER, snippet, END_PRODUCT_ID_DELIMITER].join("");
    case DelimiterNames.PRODUCT_RENDER:
      return [BEGIN_PRODUCT_RENDER_DELIMITER, snippet, END_PRODUCT_RENDER_DELIMITER].join("");
    default:
      const exhaustiveCheck: never = delimiter;
      return exhaustiveCheck;
  }
}

export function renderLiquidIdentifier(
  identifier: string,
  { asString, filters = [] }: { asString?: boolean; filters?: string[] } = {}
) {
  const identifierWithBraces = `{{ ${identifier} ${filters?.map(filter => `| ${filter}`).join(" ")} }}`;
  return asString ? `"${identifierWithBraces}"` : `${identifierWithBraces}`;
}

type LiquidHiddenInputConfig = {
  id: string;
  name: string;
  value: string;
};

export function createLiquidHiddenInput({ id, name, value }: LiquidHiddenInputConfig) {
  return `<input id="${id}" type="hidden" name="${name}" value="${value}" />
`;
}

export function createLiquidHiddenInputs(inputConfigs: LiquidHiddenInputConfig[]) {
  return inputConfigs.map(createLiquidHiddenInput).join("");
}

export function createLiquidHiddenPropertyInputs(inputConfigs: LiquidHiddenInputConfig[]) {
  return createLiquidHiddenInputs(
    inputConfigs.map(config => ({
      ...config,
      name: `properties[${config.name}]`,
    }))
  );
}
