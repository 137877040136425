import { appPrefixedHost } from "../../../constants";

export const CustomerJsonString = `{
        shopifyId: "{{ customer.id }}",
        {% if customer.email %}
          email: "{{ customer.email }}",
        {% endif %}
        {% if customer.phone %}
          phone: "{{ customer.phone }}",
        {% endif %}
        {% if customer.first_name %}
          firstName: {{ customer.first_name | json }},
        {% endif %}
        {% if customer.last_name %}
          lastName: {{ customer.last_name | json }},
        {% endif %}
        trackShipmentText:  "{{ 'customer.order.track_shipment' | t }}",
        {% if customer.default_address %}
          defaultAddressId: {{ customer.default_address.id | json }},
        {% endif %}
        {% if customer.default_address %}
          defaultAddress: {{ customer.default_address | json }},
        {% endif %}
        {% if customer.state %}
          state: {{ customer.state | json }},
        {% endif %}
        customerCurrency: {{ cart.currency.iso_code | json }},
        orderCount: {{ customer.orders_count | number }} || 0,
        totalSpent: {{ customer.total_spent | json }} || 0,
        addresses: {{ customer.addresses | json }}
      }`;

// must come before actual inclusion snippet
export const CustomerPortalSecretVarCreationString = `
{% assign smartrr_myshopifydomain = shop.permanent_domain | url_encode %}
{% assign smartrr_duration = 1000 | times: 60 | times: 60 | times: 24 | times: 10 %}
{% assign smartrr_exp = "now" | date: "%s" | times: 1000 | plus: smartrr_duration %}
{% capture smartrr_token %}{% render 'smartrr_customer_portal_token' %}{% endcapture %}
{% assign smartrr_sig = customer.id | append: smartrr_myshopifydomain | append: smartrr_exp | hmac_sha256: smartrr_token %}
`;

export const CustomerPortalValidationJsonString = `{
        {% if customer and customer.id and smartrr_sig and smartrr_exp %}
          myShopifyDomain: {{ smartrr_myshopifydomain | json }} || null,
          hasSignature: true,
          shopifyId: {{ customer.id | json }},
          signature: {{ smartrr_sig | json }},
          exp: Number("{{ smartrr_exp | json }}"),
        {% else %}
          hasSignature: false
        {% endif %}
      }`;

export const SMARTRR_ACCOUNT_SNIPPET = createAccountSnippet(
  "{% render 'smartrr-account-theme' %}",
  CustomerPortalValidationJsonString,
  CustomerJsonString
);

export function createAccountSnippet(
  themeString: string,
  validationString?: string,
  liquidCustomerString?: string
) {
  // we do this "_acct_" style tag here instead of in "customerPortalConfigToTheme.ts" so that immediately upon install the
  // original account is hidden (otherwise it looks broken until they save theme)
  return `<style id="_acct_">
  #smartrr_account ~ * {
    display: none;
  }
  #smartrr_account {
    min-height: 100vh;
    position: relative;
  }

  html, body {
    min-width: 320px;
    cursor: default;
  }

  #MainContent {
    padding-top: 0 !important;
  }
</style>
${themeString}
<script src="//${appPrefixedHost}/js/smartrr-account.js"></script>
<div id="smartrr_account"></div>
${CustomerPortalSecretVarCreationString}
<script>
function initializeSmartrr() {
  if (window.smartrr && window.smartrr.account) {
    window.smartrr.account.initialize({
      ${validationString ? `validation: ${validationString},` : ""}
      ${liquidCustomerString ? `liquidCustomer: ${liquidCustomerString},` : ""}
    });
  }
}
if (["complete", "loaded"].indexOf(document.readyState) !== -1) {
  window.initializeSmartrr();
} else {
  document.addEventListener("DOMContentLoaded", function () {
    window.initializeSmartrr();
  });
}
</script>`;
}
