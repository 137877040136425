import { SMARTRR_INCLUDE_ACCOUNT_SNIPPET } from "./account.liquid";
import { SMARTRR_INCLUDE_PASSWORDLESS_SNIPPET } from "./passwordless.liquid";
import { SMARTRR_INCLUDE_PRODUCT_SNIPPET } from "./product-template.liquid";
import { SMARTRR_ACCOUNT_SNIPPET } from "./smartrr/smartrr-account.liquid";
import {
  SMARTRR_BUNDLE_CSS_SNIPPET,
  SMARTRR_BUNDLE_DIV,
  SMARTRR_BUNDLE_JS_SNIPPET,
  SMARTRR_BUNDLE_MODAL_SNIPPET,
  SMARTRR_BUNDLE_RENDER,
  TO_REPLACE_BUNDLE_HEADER_DIV,
  TO_REPLACE_BUNDLE_THEME_RENDER,
} from "./smartrr/smartrr-bundle.liquid";
import {
  SMARTRR_MAIN_PRODUCT_DIV,
  SMARTRR_MAIN_PRODUCT_FORM_ID,
  SMARTRR_MAIN_PRODUCT_FORM_ID_REMOVE_BEFORE_VALUE,
  SMARTRR_MAIN_PRODUCT_REMOVE_BEFORE_VALUE,
  SMARTRR_MAIN_PRODUCT_RENDER,
  TO_REPLACE_MAIN_PRODUCT_DIV,
  TO_REPLACE_MAIN_PRODUCT_FORM_ID,
  TO_REPLACE_MAIN_PRODUCT_RENDER,
} from "./smartrr/smartrr-main-product.liquid";
import { SMARTRR_PASSWORDLESS_SNIPPET } from "./smartrr/smartrr-passwordless-login.liquid";
import { SMARTRR_PRODUCT_SCRIPT_SNIPPET } from "./smartrr/smartrr-product-script.liquid";
import { SMARTRR_PRODUCT_STYLES_SNIPPET } from "./smartrr/smartrr-product-styles.liquid";
import { SMARTRR_PRODUCT_SNIPPET } from "./smartrr/smartrr-product.liquid";

export {
  SMARTRR_INCLUDE_PRODUCT_SNIPPET,
  SMARTRR_PRODUCT_SNIPPET,
  SMARTRR_PRODUCT_STYLES_SNIPPET,
  SMARTRR_PRODUCT_SCRIPT_SNIPPET,
};
export const customerPortalTokenAssetName = "snippets/smartrr_customer_portal_token.liquid";

export enum AssetName {
  SECTIONS_PRODUCT_TEMPLATE = "sections/product-template.liquid",
  SECTIONS_MAIN_ACCOUNT = "sections/main-account.liquid",
  SECTIONS_MAIN_LOGIN = "sections/main-login.liquid",
  SNIPPETS_SMARTRR_PRODUCT = "snippets/smartrr-product.liquid",
  SNIPPETS_SMARTRR_PRODUCT_SCRIPT = "assets/smartrr-product-script.js",
  SNIPPETS_SMARTRR_PRODUCT_STYLES = "snippets/smartrr-product-styles.liquid",
  SNIPPETS_SMARTRR_ACCOUNT = "snippets/smartrr-account.liquid",
  SNIPPETS_SMARTRR_PASSWORDLESS = "snippets/smartrr-passwordless-login.liquid",
  SNIPPETS_SMARTRR_ACCOUNT_THEME = "snippets/smartrr-account-theme.liquid",
  SNIPPETS_SMARTRR_BUNDLE_CSS = "snippets/smartrr-bundle-css.liquid",
  SNIPPETS_SMARTRR_BUNDLE_JS = "snippets/smartrr-bundle-js.liquid",
  SNIPPETS_SMARTRR_BUNDLE_MODAL = "snippets/smartrr-bundle-modal.liquid",
  LAYOUT_THEME = "layout/theme.liquid",
  SECTIONS_HEADER = "sections/header.liquid",
  TEMPLATES_CUSTOMER_LOGIN = "templates/customers/login.liquid",
}

export enum MainProductAssetName {
  SECTIONS_MAIN_PRODUCT_TEMPLATE = "sections/main-product.liquid",
  SNIPPETS_BUY_BUTTON_LIQUID_TEMPLATE = "snippets/buy-buttons.liquid",
}

export enum MainProductSnippetName {
  SMARTRR_MAIN_PRODUCT_FORM_ID = "SMARTRR_MAIN_PRODUCT_FORM_ID",
  SMARTRR_MAIN_PRODUCT_RENDER = "SMARTRR_MAIN_PRODUCT_RENDER",
  SMARTRR_MAIN_PRODUCT_DIV = "SMARTRR_MAIN_PRODUCT_DIV",
}

export interface ISmartrrLiquidAssetConfig {
  name: AssetName | MainProductAssetName;
  snippet: string;
  toReplace?: string | RegExp;
  replacePosition?: "before" | "after";
  snippetName?: string;
  toRemoveBefore?: string;
}

export const smartrrProductLiquidAssetConfigByName: {
  [key in MainProductSnippetName]: ISmartrrLiquidAssetConfig;
} = {
  [MainProductSnippetName.SMARTRR_MAIN_PRODUCT_FORM_ID]: {
    name: MainProductAssetName.SECTIONS_MAIN_PRODUCT_TEMPLATE,
    snippet: SMARTRR_MAIN_PRODUCT_FORM_ID,
    toReplace: TO_REPLACE_MAIN_PRODUCT_FORM_ID,
    replacePosition: "after",
    toRemoveBefore: SMARTRR_MAIN_PRODUCT_FORM_ID_REMOVE_BEFORE_VALUE,
    snippetName: MainProductSnippetName.SMARTRR_MAIN_PRODUCT_FORM_ID,
  },
  [MainProductSnippetName.SMARTRR_MAIN_PRODUCT_RENDER]: {
    name: MainProductAssetName.SNIPPETS_BUY_BUTTON_LIQUID_TEMPLATE,
    snippet: SMARTRR_MAIN_PRODUCT_RENDER,
    toReplace: TO_REPLACE_MAIN_PRODUCT_RENDER,
    replacePosition: "before",
    snippetName: MainProductSnippetName.SMARTRR_MAIN_PRODUCT_RENDER,
  },
  [MainProductSnippetName.SMARTRR_MAIN_PRODUCT_DIV]: {
    name: MainProductAssetName.SECTIONS_MAIN_PRODUCT_TEMPLATE,
    snippet: SMARTRR_MAIN_PRODUCT_DIV,
    toReplace: TO_REPLACE_MAIN_PRODUCT_DIV,
    replacePosition: "after",
    toRemoveBefore: SMARTRR_MAIN_PRODUCT_REMOVE_BEFORE_VALUE,
    snippetName: MainProductSnippetName.SMARTRR_MAIN_PRODUCT_DIV,
  },
};

export const smartrrLiquidAssetConfigByName: {
  [key in AssetName]: ISmartrrLiquidAssetConfig;
} = {
  [AssetName.SECTIONS_PRODUCT_TEMPLATE]: {
    name: AssetName.SECTIONS_PRODUCT_TEMPLATE,
    snippet: SMARTRR_INCLUDE_PRODUCT_SNIPPET,
    toReplace: new RegExp("{%\\s*form.*?%}"),
    replacePosition: "after",
  },
  [AssetName.SECTIONS_MAIN_ACCOUNT]: {
    name: AssetName.SECTIONS_MAIN_ACCOUNT,
    snippet: SMARTRR_INCLUDE_ACCOUNT_SNIPPET,
    toReplace: "",
    replacePosition: "before",
  },
  [AssetName.SECTIONS_MAIN_LOGIN]: {
    name: AssetName.SECTIONS_MAIN_LOGIN,
    snippet: SMARTRR_INCLUDE_PASSWORDLESS_SNIPPET,
    toReplace: "",
    replacePosition: "before",
  },
  [AssetName.TEMPLATES_CUSTOMER_LOGIN]: {
    name: AssetName.TEMPLATES_CUSTOMER_LOGIN,
    snippet: SMARTRR_INCLUDE_PASSWORDLESS_SNIPPET,
    toReplace: "",
    replacePosition: "before",
  },
  [AssetName.SNIPPETS_SMARTRR_PRODUCT]: {
    name: AssetName.SNIPPETS_SMARTRR_PRODUCT,
    snippet: SMARTRR_PRODUCT_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_PRODUCT_SCRIPT]: {
    name: AssetName.SNIPPETS_SMARTRR_PRODUCT_SCRIPT,
    snippet: SMARTRR_PRODUCT_SCRIPT_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_PRODUCT_STYLES]: {
    name: AssetName.SNIPPETS_SMARTRR_PRODUCT_STYLES,
    snippet: SMARTRR_PRODUCT_STYLES_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_ACCOUNT]: {
    name: AssetName.SNIPPETS_SMARTRR_ACCOUNT,
    snippet: SMARTRR_ACCOUNT_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_PASSWORDLESS]: {
    name: AssetName.SNIPPETS_SMARTRR_PASSWORDLESS,
    snippet: SMARTRR_PASSWORDLESS_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_ACCOUNT_THEME]: {
    name: AssetName.SNIPPETS_SMARTRR_ACCOUNT_THEME,
    snippet: "",
  },
  [AssetName.SECTIONS_HEADER]: {
    name: AssetName.SECTIONS_HEADER,
    snippet: SMARTRR_BUNDLE_DIV,
    toReplace: TO_REPLACE_BUNDLE_HEADER_DIV,
    replacePosition: "after",
  },
  [AssetName.LAYOUT_THEME]: {
    name: AssetName.LAYOUT_THEME,
    snippet: SMARTRR_BUNDLE_RENDER,
    toReplace: TO_REPLACE_BUNDLE_THEME_RENDER,
    replacePosition: "after",
  },
  [AssetName.SNIPPETS_SMARTRR_BUNDLE_CSS]: {
    name: AssetName.SNIPPETS_SMARTRR_BUNDLE_CSS,
    snippet: SMARTRR_BUNDLE_CSS_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_BUNDLE_JS]: {
    name: AssetName.SNIPPETS_SMARTRR_BUNDLE_JS,
    snippet: SMARTRR_BUNDLE_JS_SNIPPET,
  },
  [AssetName.SNIPPETS_SMARTRR_BUNDLE_MODAL]: {
    name: AssetName.SNIPPETS_SMARTRR_BUNDLE_MODAL,
    snippet: SMARTRR_BUNDLE_MODAL_SNIPPET,
  },
};

export const smartrrSnippets = [
  smartrrLiquidAssetConfigByName["snippets/smartrr-account.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-passwordless-login.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-product.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-product-styles.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-account-theme.liquid"],
  smartrrLiquidAssetConfigByName["assets/smartrr-product-script.js"],
];

export const smartrrSnippetsWithoutProduct = [
  smartrrLiquidAssetConfigByName["snippets/smartrr-account.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-passwordless-login.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-account-theme.liquid"],
];

export const smartrrProductPageInjection = [smartrrLiquidAssetConfigByName["sections/product-template.liquid"]];
export const smartrrMainProductFormIdInjection = [
  smartrrProductLiquidAssetConfigByName.SMARTRR_MAIN_PRODUCT_FORM_ID,
];
export const smartrrMainProductDivInjection = [smartrrProductLiquidAssetConfigByName.SMARTRR_MAIN_PRODUCT_DIV];
export const smartrrMainProductRenderInjection = [
  smartrrProductLiquidAssetConfigByName.SMARTRR_MAIN_PRODUCT_RENDER,
];

// bundle injects
export const smartrrBundleSnippets = [
  smartrrLiquidAssetConfigByName["snippets/smartrr-bundle-css.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-bundle-js.liquid"],
  smartrrLiquidAssetConfigByName["snippets/smartrr-bundle-modal.liquid"],
];
export const smartrrBundleRenderInjection = [smartrrLiquidAssetConfigByName["layout/theme.liquid"]];

export const smartrrCustomerAccountPageInjection = [
  smartrrLiquidAssetConfigByName["sections/main-account.liquid"],
];

export const smartrrCustomerLoginPageInjection = [smartrrLiquidAssetConfigByName["sections/main-login.liquid"]];
