import { appPrefixedHost } from "@smartrr/shared/constants";

export const SMARTRR_PASSWORDLESS_SNIPPET = createPasswordlessSnippet();

export function createPasswordlessSnippet() {
  return `<div id="smartrr_passwordless_login"></div>
  <style>
    /*
    #smartrr_passwordless_form ~ * {
      display: none;
    }
    */

    .smartrr-hide-siblings ~ * {
      display: none;
    }

    #smartrr_passwordless_form {
      max-width: unset;
    }

    #smartrr_passwordless_login {
      min-height: 100vh;
      position: relative;
    }

    #smartrr_passwordless_login .login p {
      margin: 3px 14px 0;
    }

    .shopify-section {
      background-color: #f4f4f4
    }

    .customer #smartrr_passwordless_login form {
      margin-top: 0;
    }

    #smartrr_passwordless_form .form__errors {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .form__errors + #smartrr_passwordless_login > div {
      margin-top: 0;
    }

    .form__errors .errors ul {
      padding-left: 0;
    }
  </style>
{% assign smartrr_myshopifydomain = shop.permanent_domain | url_encode %}
{% render 'smartrr-account-theme' %}
<script src="//${appPrefixedHost}/js/smartrr-passwordless-login.js"></script>

<script>
  function initializeSmartrrPasswordless() {
    if (window.smartrr && window.smartrr.otp) {
      window.smartrr.otp.initialize({
        orgShopifyDomain: {{ smartrr_myshopifydomain | json }} || null
      });
    }
  }

  function unhideSiblingElements(smartrrForm) {
    smartrrForm.classList.remove('smartrr-hide-siblings');
  }

  function checkErrors() {
    const smartrrPasswordlessForm = document.getElementById('smartrr_passwordless_form'),
          smartrrPasswordlessContainer = document.getElementById('smartrr_passwordless_login');

    setTimeout(function() {
      if(!smartrrPasswordlessContainer.hasChildNodes()) {
        unhideSiblingElements(smartrrPasswordlessForm);
      }
    }, 1000)
  }

  if (["complete", "loaded"].indexOf(document.readyState) !== -1) {
    window.initializeSmartrrPasswordless();
    checkErrors();
    } else {
    document.addEventListener("DOMContentLoaded", function () {
      window.initializeSmartrrPasswordless();
      checkErrors();
    });
  }
  document.getElementById("smartrr_passwordless_form").style.backgroundColor = window.smartrr.theme.modernPageBackground;
</script>`;
}
